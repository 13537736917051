import React from 'react'
import { Helmet } from 'react-helmet'
import { navigate } from 'gatsby'
import Footer from '../components/Footer'
import Navbar from '../components/Navbar'
import './all.sass'
import { useStaticQuery, graphql, withPrefix } from 'gatsby'
import { isLoggedIn } from "../services/auth"
import Login from "../components/Login";

const TemplateWrapper = ({ children }) => {
  const { site, allFile } = useStaticQuery(
    graphql`
      query DEFAULT {
        site {
          siteMetadata {
            title
            description
          }
        }
      }
    `
  )
  
  let isLoggedInValue = isLoggedIn()
  if (!isLoggedInValue) {
    return (
      <>
      <Helmet>
        <html lang="en" className="grey" />
      </Helmet>
      <Login />
      </>
    )
  }
  else {
    return (
      <>
        <Helmet>
          <html lang="en" className="white" />
          <title>{site.siteMetadata.title}</title>
          <meta name="description" content={site.siteMetadata.description} />
          <meta name="theme-color" content="#fff" />

          <meta property="og:type" content="business.business" />
          <meta property="og:title" content={site.siteMetadata.title} />
          <meta property="og:url" content="/" />
          <meta
            property="og:image"
            content={`${withPrefix('/')}img/og-image.jpg`}
          />
        </Helmet>
        <Navbar />
        <div>{children}</div>
        <Footer />
      </>
    )
  }
}

export default TemplateWrapper
