import React from "react"
import { navigate } from "gatsby"
import { handleLogin, isLoggedIn } from "../services/auth"

class Login extends React.Component {
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.state = {
      username: ``,
      password: ``,
      isClient: false
    }
  }
  
  handleUpdate = event => {
    this.setState({
      [event.target.name]: event.target.value,
    })
  }
  handleSubmit = event => {
    event.preventDefault()
    handleLogin(this.state)
  }
  render() {
    if (isLoggedIn()) {
      navigate(`/`)
    }
    return (
      <section className="section" style={{maxWidth: `400px`,margin: `40px auto`, border: `1px solid #ddd`, background: `white`}}>
        <div className="container">
          <h1 class="title">Log in</h1>
        <form
          method="post"
          onSubmit={event => {
            this.handleSubmit(event)
            navigate(`/`)
          }}
        >
          <div class="field">
            <label class="label">Username</label>
            <div class="control">
                <input className="input" type="text" name="username" defaultValue="" value={this.state.username} onChange={this.handleUpdate} />
            </div>
          </div>
            <div class="field">
              <label class="label">Password</label>
              <div class="control">
                <input className="input" type="password" name="password" defaultValue="" value={this.state.password} onChange={this.handleUpdate} />
              </div>
            </div>
            <input type="submit" className="button is-link" value="Log In" />
        </form>
      </div>
      </section>
    )
  }
  componentDidMount() {
    this.setState({ isClient: true })
  }
}
export default Login